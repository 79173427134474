import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Timeline, { TimelineHeaders, SidebarHeader, DateHeader } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import { Alert, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import Header from './Header';
import { checkError, logError } from '../utils/helpers';
import { fetch } from '../utils/httpUtil';
import { blocks, reservationStatuses } from '../constants/roomAttributes';
import '../css/calendar.css';  // CSS for RoomsCalendar component

function RoomsCalendar() {
    const [blockItems, setBlockItems] = useState([]);
    const [items, setItems] = useState([]);
    const [groups, setGroups] = useState([]);
    const [alertMsg, setAlertMsg] = useState();
    const [alertColor, setAlertColor] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [selectedBlock, setSelectedBlock] = useState('0');
    const [defaultTimeStart, setDefaultTimeStart] = useState(moment().startOf('week').valueOf());
    const [defaultTimeEnd, setDefaultTimeEnd] = useState(moment().endOf('week').valueOf());

    const navigate = useNavigate();

    const keys = {
        groupIdKey: "id",
        groupTitleKey: "title",
        groupRightTitleKey: "rightTitle",
        itemIdKey: "id",
        itemTitleKey: "title",
        itemDivTitleKey: "title",
        itemGroupKey: "group",
        itemTimeStartKey: "start_time",
        itemTimeEndKey: "end_time",
        groupLabelKey: "title"
    };

    useEffect(() => {
        if (sessionStorage.getItem('authenticated') === 'true') {
            for (let i = 1; i <= 12; i++) {
                if (blocks[i] !== 'Wait List') {
                    setBlockItems(prevBlockItems => [...prevBlockItems, blocks[i]]);
                }
            }
        } else {
            navigate("/", { replace: true });
        }
    }, [navigate]);

    useEffect(() => {
        if (selectedBlock !== '0') {
            fetchBlockRooms();
            fetchBookedRoomsForWeek();
        }
    }, [selectedBlock]);

    useEffect(() => {
        if (selectedBlock !== '0') {
            fetchBookedRoomsForWeek();
        }
    }, [defaultTimeStart]);

    const handleBlockChange = (event) => {
        setSelectedBlock(event.target.value);
    };

    const getBackgroundColor = (reservation_type_id) => {
        switch(reservation_type_id) {
            case 1: return 'beige';
            case 2: return 'wheat';
            case 3: return '#c8d0d9';
            case 4: return 'beige';
            case 5: return 'gainsboro';
            case 6: return 'mintcream';
            case 7: return 'khaki';
            default: return 'orange';
        }
    };

    const fetchBlockRooms = async () => {
        fetch(`apiRooms/?bId=${selectedBlock}`)
            .then((response) => checkError(response))
            .then((result) => {
                const arrRooms = result.map((item) => ({
                    id: item.room_id,
                    title: item.room_no
                }));
                setGroups(arrRooms);
            })
            .catch((error) => {
                setAlertMsg("Oops! Something went wrong! Please try again!");
                setAlertColor("danger");
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 2000);
                logError(error);
            });
    };

    const fetchBookedRoomsForWeek = async () => {
        const startDate = moment(defaultTimeStart).format('YYYY-MM-DD');
        fetch(`apiRooms/${selectedBlock}?sdate=${startDate}`)
            .then((response) => checkError(response))
            .then((result) => {
                const arrEvents = result.map((item) => {    
                    return {
                        rId: item.reservation_id,
                        rTypeId: item.reservation_type_id,
                        rStatusId: item.reservation_status_id,
                        gId: item.guest_id,
                        id: item.room_booking_id,
                        group: item.room_id,
                        title: item.guest_name, // Just show the guest_name in the title
                        start_time: moment(item.start_date).valueOf(),
                        end_time: moment(item.end_date).endOf('day').valueOf(),
                        itemProps: {
                            style: {
                                background: getBackgroundColor(item.reservation_type_id),
                                color: 'black',
                                position: 'relative' // Ensure absolute positioning of vertical line works
                            },
                            className: item.reservation_status_id === 4 ? 'vertical-line-item' : ''
                        }
                    };
                });
                setItems(arrEvents);
            })
            .catch((error) => {
                setAlertMsg("Oops! Something went wrong! Please try again!");
                setAlertColor("danger");
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 2000);
                logError(error);
            });
    };
    
    
    

    // const clearItems = () => {
    //     setItems([]);
    // };

    const handlePrevWeek = () => {
        setDefaultTimeStart(moment(defaultTimeStart).subtract(1, 'week').startOf('week').valueOf());
        setDefaultTimeEnd(moment(defaultTimeStart).subtract(1, 'week').endOf('week').valueOf());
    };

    const handleNextWeek = () => {
        setDefaultTimeStart(moment(defaultTimeStart).add(1, 'week').startOf('week').valueOf());
        setDefaultTimeEnd(moment(defaultTimeStart).add(1, 'week').endOf('week').valueOf());
    };

    const editItem = (itemId) => {
        const item = items.find(item => item.id === itemId);

        const reservationID = item ? item.rId : null;
        const rTypeID = item ? item.rTypeId : null;  
        const rStatusID = item ? item.rStatusId : null; 
        const gID = item ? item.group : null;   
        const guestName = item ? item.title : null;

        const reservationItem = {
            rTypeId: rTypeID, 
            rStatusId: rStatusID, 
            rId: reservationID, 
            gId: gID, 
            guestName: guestName
          }
      
          navigate("/reservationContainer", { state: {reservationItem } });
    }

    // const handleZoom = (timelineContext, unit) => {
    //     if (unit === 'day') {
    //         setDefaultTimeStart(moment(timelineContext.visibleTimeStart).valueOf());
    //         setDefaultTimeEnd(moment(timelineContext.visibleTimeEnd).valueOf());
    //     } else if (unit === 'month') {
    //         clearItems();
    //     }
    // }

    return (
        <>
            <Header selected="Reservations" />
            <div className="divError">
                <div className="divPageContainer">
                    <h3>Rooms Calendar</h3>
                    <hr />
                    <Alert color={alertColor} isOpen={showAlert}>
                        {alertMsg}
                    </Alert>
                    <Row>
                        <Col md={2}>
                            <FormGroup>
                                <Label className="form-label">Blocks:</Label>
                                <Input
                                    type="select"
                                    id="slBlocks"
                                    required
                                    className="form-control"
                                    value={selectedBlock}
                                    onChange={handleBlockChange}
                                >
                                    <option value="0">Please select</option>
                                    {blockItems.length > 0 && blockItems.map((item, index) => (
                                        <option key={index + 1} value={index + 1}>{item}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div style={{ marginTop: '1em' }}>
                        {selectedBlock !== '0' && (
                            <>
                                <div className="divFloatRight" style={{ marginTop: '-4em' }}>
                                    <button onClick={handlePrevWeek} className="btnBig" style={{ marginTop: '0', marginLeft: '2em', backgroundColor: 'cornflowerblue' }}>Previous Week</button>
                                    <button onClick={handleNextWeek} className="btnBig" style={{ marginTop: '0', marginLeft: '2em', backgroundColor: 'cornflowerblue' }}>Next Week</button>
                                </div>
                                {groups.length > 0 && (
                                    <Timeline
                                        key={`${defaultTimeStart}-${defaultTimeEnd}`}
                                        groups={groups}
                                        items={items}
                                        keys={keys}
                                        defaultTimeStart={defaultTimeStart}
                                        defaultTimeEnd={defaultTimeEnd}
                                        sidebarWidth={200}
                                        buffer={1}
                                        onItemClick = {(itemId) => editItem(itemId)}  
                                        stackItems={true} // Enables stacking to prevent overlap
                                        itemHeightRatio={0.75} // Adjust item height if needed       

                                        // onZoom={(timelineContext, unit) => handleZoom(timelineContext, unit)}
                                    >
                                        <TimelineHeaders className="sticky" style={{ backgroundColor: 'unset', fontWeight: 'bold' }}>
                                            <SidebarHeader>
                                                {({ getRootProps }) => {
                                                    return <div {...getRootProps()}></div>;
                                                }}
                                            </SidebarHeader>
                                            <DateHeader unit="primaryHeader" style={{ backgroundColor: 'rosybrown', fontWeight: 'bold' }} />
                                            <DateHeader unit="week" />
                                            <DateHeader style={{ backgroundColor: 'wheat', fontWeight: 'bold' }} />
                                        </TimelineHeaders>
                                    </Timeline>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default RoomsCalendar;
